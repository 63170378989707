#about {
  padding: 60px 20px;
  background-color: #f9fafc;
}

.about-container {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
  flex-wrap: wrap; /* Allows flex items to wrap on small screens */
}

.about-image {
  flex: 1;
  text-align: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  position: relative;
  top: 40px;
}

.about-text {
  flex: 1.5;
  padding-left: 30px;
}

.about-text h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.about-text p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
}

.about-values {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.value-item {
  text-align: center;
}

.value-item h3 {
  font-size: 24px;
  color: #0066cc;
  margin-bottom: 15px;
}

.value-item p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  #about {
      padding: 40px 20px;
  }

  .about-container {
      flex-direction: column; /* Stack the content vertically on smaller screens */
      text-align: center;
  }

  .about-image {
      margin-bottom: 20px;
  }

  .about-text {
      flex: 1;
      padding-left: 0;
      padding-top: 20px;
  }

  .about-text h2 {
      font-size: 28px; /* Slightly smaller font size */
  }

  .about-text p {
      font-size: 16px; /* Adjust paragraph font size for mobile */
  }

  .about-values {
      grid-template-columns: repeat(2, 1fr); /* Two columns for values on mobile */
      gap: 15px;
  }

  .value-item h3 {
      font-size: 20px; /* Smaller heading size on mobile */
  }

  .value-item p {
      font-size: 14px; /* Adjust text size for mobile */
  }
}

@media (max-width: 480px) {
  #about {
      padding: 30px 15px;
  }

  .about-text h2 {
      font-size: 24px; /* Further reduce heading size */
  }

  .about-text p {
      font-size: 14px; /* Adjust text size for very small screens */
  }

  .about-values {
      grid-template-columns: 1fr; /* Single column layout for very small screens */
  }

  .value-item h3 {
      font-size: 18px; /* Adjust heading size for small screens */
  }

  .value-item p {
      font-size: 13px; /* Adjust text size for small screens */
  }
}
