.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  #category{
    cursor: pointer;
  }
  
  .modal {
    background-color: white;
    padding: 20px 30px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal h2 {
    margin-top: 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
    background-color: #f9f9f9;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    width: 48%; /* Each button takes up 48% of the container width */
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .modal-buttons .submit-btn {
    background-color: #ff6600;
    color: white;
  }
  
  .modal-buttons .submit-btn:hover {
    background-color: #e65c00;
  }
  
  .modal-buttons .close-btn {
    background-color: #ccc;
    color: black;
  }
  
  .modal-buttons .close-btn:hover {
    background-color: #999;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  