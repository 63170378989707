.homepage {
  text-align: center;
  padding: 50px 20px;
}

.homepage h1 {
  color: #0066cc;
  font-size: 32px;
  margin-bottom: 10px;
}

.homepage p {
  color: #333;
  font-size: 18px;
  margin-bottom: 20px;
}

.homepage button {
  background-color: #ff6600;
  color: white;
  padding: 12px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: inline-block;  /* Ensure button is inline-block */
  width: auto;            /* Set button width to auto */
  margin: 0 auto;         /* Center the button */
}

.homepage button:hover {
  transform: scale(1.05);
}
