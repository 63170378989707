#products {
    padding: 50px 20px;
    background-color: #fff;
  }
  
  #products h2 {
    color: #333;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .product-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .product-item {
    flex: 1 1 200px;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    text-align: center;
    border-radius: 8px;
    transition: transform 0.3s ease; /* Transition for the entire product item */
  }
  
  .product-item img {
    max-width: 300px;
    margin-bottom: 10px;
    transition: transform 0.3s ease; /* Transition for the image */
  }
  
  .product-item h3 {
    margin-bottom: 10px;
    color: #0066cc;
  }
  
  .product-item p {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
  }
  
  /* Zoom-in effect on hover */
  .product-item:hover {
    transform: scale(1.05); /* Slightly enlarge the entire product item */
    cursor: pointer;
  }
  
  .product-item:hover img {
    transform: scale(1.2); /* Enlarge the image on hover */
  }
  