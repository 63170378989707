* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  background-color: #2c3e50;
  color: white;
  padding: 10px 20px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  flex-wrap: wrap;
}

.company-logo img {
  height: 80px;
  transition: all 0.4s;
}

.company-logo img:hover {
  height: 90px;
  cursor: pointer;
}

.company-title {
  font-size: 24px;
  font-weight: bold;
  color: #ecf0f1;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.company-name {
  margin-left: 10rem;
}

.contact-info p {
  font-size: 14px;
  text-align: right;
  margin: 0;
  flex-grow: 1;
}


nav {
  background-color: #2c3e50;
  padding: 10px 0;
}

.nav-menu {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.nav-menu li {
  position: relative;
}

.nav-menu li a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 26px;
  font-weight: bold;
}

.nav-menu li a:hover {
  color: #f39c12;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #34495e;
  padding: 10px 0;
  list-style: none;
  text-align: left;
  min-width: 150px;
  border-radius: 4px;
  z-index: 1;
}

.dropdown-content li {
  padding: 8px 16px;
}

.dropdown-content li a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.dropdown-content li a:hover {
  background-color: #f39c12;
  color: #2c3e50;
  border-radius: 4px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.social-icons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-icons a {
  color: aliceblue;
}

/* Responsive Design */
@media (max-width: 1145px) {

  .top-bar {
    flex-direction: column;
    align-items: center;
  }

  .company-title {
    font-size: 20px;
    text-align: center;
  }

  .contact-info p {
    text-align: center;
    font-size: 12px;
  }

  .company-name {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .nav-menu {
    flex-direction: column;
    align-items: center;
  }

  .company-logo img {
    height: 60px;
  }

  .social-icons {
    justify-content: center;
  }

  .top-bar {
    text-align: center;
  }

  .contact-info p {
    font-size: 12px;
    text-align: center;
  }

  .company-title {
    font-size: 22px;
  }

  .company-name {
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .company-title {
    font-size: 18px;
  }

  .contact-info p {
    font-size: 10px;
  }

  .nav-menu li a {
    font-size: 14px;
  }

  .social-icons {
    gap: 10px;
  }

  .company-name {
    margin-left: 0;
  }
}