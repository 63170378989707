#contact {
    background-color: #333;
    padding: 50px 20px;
  }
  
  .contact-header {
    text-align: center;
    color: white;
    padding: 20px 0;
  }
  
  .contact-content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .contact-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-image img {
    width: 95%;
    height: 70%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .contact-form {
    flex: 1;
    padding: 30px;
    color: white;
  }
  
  .contact-form p {
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
  }
  
  button {
    background-color: #ff6600;
    color: white;
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
  }
  
  button:hover {
    background-color: #e65c00;
  }
  