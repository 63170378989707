/* Footer Styles */
.footer {
  background-color: #f8f9fa;
  padding: 40px 20px;
  border-top: 3px solid #f5a623;
  color: #333;
}

.footer-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.footer-column {
  width: 100%; 
  margin-bottom: 20px; 
}

.footer-column h3 {
  color: #333;
  font-size: 16px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #333;
  text-decoration: none;
  font-size: 18px; 
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-column p {
  margin-bottom: 10px;
  font-size: 16px; 
}

.footer-column a {
  color: #333;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.social-icons-footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
}

.social-icons-footer a {
  font-size: 20px; 
  color: #333;
  text-decoration: none;
}

.social-icons-footer a:hover {
  color: #f5a623;
}

.footer-bottom {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  text-align: center;
  margin-top: 30px;
}

.footer-bottom p {
  margin: 5px 0;
  font-size: 14px; /* Smaller text for footer bottom */
}

.footer-bottom a {
  color: #f5a623;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

/* WhatsApp Chat Button Styles */
.whatsapp-chat {
  position: fixed;
  bottom: 20px; 
  left: 20px;   /* Positioning it in the bottom-left corner */
  z-index: 100;
}

.whatsapp-button {
  position: relative;
  display: inline-block;
}

.whatsapp-button img {
  width: 50px;  /* Icon size */
  height: 50px;
  border-radius: 50%;
  background-color: #25D366;
}

.whatsapp-tooltip {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Tooltip appears above the icon */
  left: 50%;
  margin-left: -40px;
  opacity: 0;
  transition: opacity 0.3s;
}

.whatsapp-button:hover .whatsapp-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .footer-column {
    width: 30%; /* Three columns for tablet-sized screens */
  }

  .social-icons-footer {
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .footer-column {
    width: 23%; /* Four columns for desktop screens */
  }
  .social-icons-footer {
    justify-content: center;
  }

  .footer-container {
    gap: 40px; /* More space for desktop layout */
  }
}
